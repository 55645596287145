<template>
    <div class="SupplyAndPurchasePublish">
        <el-page-header @back="$router.go(-1)" content="发布求购"></el-page-header>
        <div class="f-cl box">
            <div>求购名称: <span>*</span></div>
            <el-input :autosize="{ minRows: 2 }" maxlength="20" show-word-limit v-model="postHead"
                placeholder="请输入"></el-input>
        </div>
        <div class="f-cl box">
            <div>求购内容: <span>*</span></div>
            <el-input :autosize="{ minRows: 2 }" maxlength="500" show-word-limit v-model="postContent" placeholder="请输入"
                type="textarea"></el-input>
        </div>
        <div class="image-box f-c" v-if="queryType == 'MyPurchase'">
            <UploadOtherImage :imageUrl="AllContent.mobject1" :imageDiff="'mobject1'" @FormData="FormData"
                @deleteUploadImage="deleteUploadImage"></UploadOtherImage>
            <UploadOtherImage :imageUrl="AllContent.mobject2" :imageDiff="'mobject2'" @FormData="FormData"
                @deleteUploadImage="deleteUploadImage"></UploadOtherImage>
            <UploadOtherImage :imageUrl="AllContent.mobject3" :imageDiff="'mobject3'" @FormData="FormData"
                @deleteUploadImage="deleteUploadImage"></UploadOtherImage>
            <UploadOtherImage :imageUrl="AllContent.mobject4" :imageDiff="'mobject4'" @FormData="FormData"
                @deleteUploadImage="deleteUploadImage"></UploadOtherImage>
            <UploadOtherImage :imageUrl="AllContent.mobject5" :imageDiff="'mobject5'" @FormData="FormData"
                @deleteUploadImage="deleteUploadImage"></UploadOtherImage>
        </div>
        <div class="image-box f-c" v-else>
            <template v-if="imageArr && imageArr.length">
                <div class="has-img" v-for="(item, index) of imageArr" :key="index">
                    <el-image style="width: 130px; height: 130px" fit="contain" :src="item"></el-image>
                    <i @click="deleteImg(index)" class="delete-btn el-icon-close"></i>
                </div>
            </template>
            <UploadOtherImage v-if="imageArr && imageArr.length < 5" @FormData="FormData"></UploadOtherImage>
        </div>
        <!-- 修改求购 -->
        <div class="btns f-c-a" v-if="queryType == 'MyPurchase'">
            <el-button type="primary" round @click="handleBtn('S')">保存</el-button>
            <el-button type="primary" round @click="handleBtn('N')">发布</el-button>
        </div>
        <!-- 发布求购 -->
        <div class="btns f-c-a" v-else>
            <el-button type="primary" round @click="handleBtn(2)">保存</el-button>
            <el-button type="primary" round @click="handleBtn(1)">发布</el-button>
        </div>
    </div>
</template>

<script>



import { UrlDecodeType } from '@/mixins/UrlDecodeMixin';

export default {
    name: 'SupplyAndPurchasePublish',
    mixins: [UrlDecodeType],
    data() {
        return {
            postHead: '',//求购名称
            postContent: '',//求购内容
            imageArr: [],
            AllContent: {},//所有详情信息
            IpAddressInfo: {}
        }
    },
    created() {
        if (this.queryType == 'MyPurchase') {
            let data = this.$getStorage('SupplyAndPurchaseDetailContent');
            this.postHead = data.postHead;
            this.postContent = data.postContent;
            this.imageArr = data.imgUrlList
            this.AllContent = data;
        }
        this.$CommonRequests.getIpAddress().then((res) => {
            if (res.code == 200) {
                this.IpAddressInfo = res.data
            }
        })
    },
    methods: {
        FormData(params, type) {
            let that = this;
            var formData = new FormData();
            formData.append("userId", parseInt(that.$store.state.userId));
            formData.append("token", that.$getStorage("token"));
            formData.append("file", params.file);
            formData.append("fileNameState", "buy");
            that.uploadForumOssFile(formData, type);
        },
        // 产品图片上传接口
        uploadForumOssFile(formData, type) {
            let that = this;
            that.$http.uploadForumOssFile(formData).then(res => {
                if (res.code == 200) {
                    if (this.queryType == 'MyPurchase') {
                        let imageType = {
                            mobject1: () => { this.AllContent.mobject1 = res.data },
                            mobject2: () => { this.AllContent.mobject2 = res.data },
                            mobject3: () => { this.AllContent.mobject3 = res.data },
                            mobject4: () => { this.AllContent.mobject4 = res.data },
                            mobject5: () => { this.AllContent.mobject5 = res.data },
                        }
                        imageType[type] ? imageType[type]() : ''
                    } else {
                        that.imageArr.push(res.data)
                    }
                    that.$common.message(res.msg, "success", 1200);
                }
            })
        },
        // 求购发布
        addForumBuy(saveState) {
            let that = this, { postHead, postContent } = that,
                [mobject1 = '', mobject2 = '', mobject3 = '', mobject4 = '', mobject5 = ''] = that.imageArr,
                { ip, province, city, districts } = that.IpAddressInfo;
            that.$http.addForumBuy({
                postUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                saveState,//保存状态(1:发布状态,2:保存状态)
                postHead,
                postContent,
                mobject1,
                mobject2,
                mobject3,
                mobject4,
                mobject5,
                previewContent: postContent.slice(0, 100),
                postLocation: province + '*' + city + '*' + districts,//来源
                sourceIp: ip//发帖来源IP
            }).then(res => {
                if (res.code == 200) {
                    that.$common.message(res.msg, 'success', 1200)
                    that.$common.DelayedBack()
                }
            })
        },
        //  用户修改供应求购帖子接口
        reviseForumBuy(saveState) {
            let that = this, { postHead, postContent } = that,
                { mobject1, mobject2, mobject3, mobject4, mobject5, lastModifyDate } = that.AllContent,
                { ip, province, city, districts } = that.IpAddressInfo;
            that.$http.reviseForumBuy({
                postId: that.AllContent.postId,
                postUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                saveState,//保存状态(1:发布状态,2:保存状态)
                postHead,
                postContent,
                mobject1,
                mobject2,
                mobject3,
                mobject4,
                mobject5,
                previewContent: postContent.slice(0, 100),
                lastModifyDate,
                postLocation: province + '*' + city + '*' + districts,//来源
                sourceIp: ip//发帖来源IP
            }).then(res => {
                if (res.code == 200) {
                    that.$common.message(res.msg, 'success', 1200)
                    that.$common.DelayedBack()
                    that.$removeStorage('SupplyAndPurchaseDetailContent')
                }
            })
        },
        // 触发保存或发布按钮
        handleBtn(saveState) {
            let that = this, { postHead, postContent } = that;
            if (!postHead) {
                return that.$common.message('请输入求购名称', 'warning', 1200)
            }
            if (!postContent) {
                return that.$common.message('请输入求购内容', 'warning', 1200)
            }
            that.$confirm(`是否${saveState == 2 || saveState == 'S' ? '保存' : '发布'}求购信息？`, '提示').then(() => {
                this.queryType == 'MyPurchase' ? that.reviseForumBuy(saveState) : that.addForumBuy(saveState)
            }).catch(() => { })
        },
        // 删除image
        deleteImg(index) {
            this.imageArr.splice(index, 1)
        },
        // 修改图片
        deleteUploadImage(data) {
            const imageName = {
                mobject1: () => { this.AllContent.mobject1 = 'D' },
                mobject2: () => { this.AllContent.mobject2 = 'D' },
                mobject3: () => { this.AllContent.mobject3 = 'D' },
                mobject4: () => { this.AllContent.mobject4 = 'D' },
                mobject5: () => { this.AllContent.mobject5 = 'D' },
            }
            imageName[data] ? imageName[data]() : ''
        },
    },
    beforeDestroy() {
        this.$removeStorage('SupplyAndPurchaseDetailContent')
    }
}

</script>
<style lang='less' scoped>
.SupplyAndPurchasePublish {
    .box {
        padding: 20px;

        span {
            color: red;
        }

        .el-input,
        .el-textarea {
            margin-top: 20px;
        }
    }

    .image-box {
        padding: 20px;

        .has-img {
            position: relative;
            margin-right: 50px;

            .delete-btn {
                position: absolute;
                left: 140px;
                top: -10px;
                border: 1px solid #333;
                border-radius: 50%;
                padding: 2px;
                color: #333;
                background: #fff;
            }

            .delete-btn:hover {
                background: red;
                border: 1px solid red;
                color: #fff;
            }
        }
    }

    .btns {
        margin: 50px 0;

        .el-button {
            width: 20%;
        }
    }

}
</style>
